<template>
  <div class="centerwrap">
    <div class="centerbox">
      <div class="bannerbox">
        <img src="../../../assets/imgs/banner1.png" alt />
      </div>
      <el-input
        placeholder="团队搜索"
        v-model="searchInp"
        class="searchteam"
        @keyup.enter.native="getTeam(1,searchInp)"
      >
        <i slot="suffix" class="iconfont iconxingzhuang2" @click="getTeam(1,searchInp)"></i>
      </el-input>

      <ul class="teamlist" v-loading="loading">
        <li v-for="(item,index) in teamData">
          <div class="onebox">
            <router-link tag="span" :to="{name:'teamsBook',params:{tid: item.Id}}" class="left">
              <img :src="item.Cover" alt />
            </router-link>
            <!-- {name:'teamsBook',params:{tid: item.Tid}} -->
            <div class="right">
              <div class="teammember">
                <span >
                  <img :src="item.Avatar" alt />
                </span>
              </div>
              <h2 class="titleh2">
                <router-link tag="span" :to="{name:'teamsBook',params:{tid: item.Id}}">{{item.Team}}</router-link>
              </h2>
              <!-- <p class="textp">{{item.Slogan}}</p> -->
              <div class="btnbox">
                <el-button
                  type="success"
                  class="addteam"
                  plain
                  round
                  size="mini"
                  v-if="item.Status==1"
                >已加入该团队</el-button>
                <!-- //0 非成员，1成员，2审核中 -->
                <el-button
                  type="warning"
                  class="addteam"
                  plain
                  round
                  size="mini"
                  v-else-if="item.Status==2"
                >审核中</el-button>
                <el-button
                  type="primary"
                  class="addteam"
                  plain
                  round
                  size="mini"
                  @click="handelJoinTeam(item.Id)"
                  v-else
                >加入该团队</el-button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <p v-if="showNomore" class="nomore">暂无数据</p>
      <el-pagination
        background
        v-if="total>pageCount"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :page-size.sync="pageCount"
        :current-page="currentPage"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { teamRequest, teamJoinRequest } from "@/api/project.js";
export default {
  data() {
    return {
      teamData: [],
      currentPage: 1,
      total: "",
      pageCount: 10,
      loading: true,
      searchInp: "",
      showNomore: false,
    };
  },
  created() {
    this.getTeam(1);
  },
  methods: {
    showtext(e) {
      e.currentTarget.className = "showtext";
    },
    hidetext(e) {
      e.currentTarget.className = "";
    },
    async getTeam(val, search) {
      try {
        this.loading = true;
        this.showNomore = false;
        setTimeout(() => {
          this.loading = false;
        }, 3000);
        val = val || "1";
        search = search || "";
        const result = await teamRequest({
          page: val,
          team: search,
        });
        if (result.status == 200) {
          this.loading = false;
          this.teamData = result.data.list;
          this.total = result.data.totalCount;
          this.pageCount = result.data.pageCount;
          if (result.data.list.length == 0) this.showNomore = true;
        }
      } catch (err) {}
    },
    handleCurrentChange(val) {
      this.getTeam(val, this.searchInp);
    },
    async joinTeam(tid, reason) {
      try {
        const result = await teamJoinRequest({
          tid: tid,
          reason: reason,
        });
        if (result.status == 200) {
          this.$message({
            type: "success",
            message: `${result.message}`,
          });
        } else {
          this.$message({
            type: "info",
            message: `${result.message}`,
          });
        }
      } catch (err) {}
    },
    handelJoinTeam(id) {
      var _this = this;
      this.$prompt("请输入申请理由", "加入该团队", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /[\s\S]{1,120}/,
        inputErrorMessage: "请输入申请理由",
        center: true,
      })
        .then(({ value }) => {
          _this.joinTeam(id, value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
  },
};
</script>
<style  lang="less" scoped>
.centerwrap {
  position: relative;
  margin: -15px -15px 15px;
  overflow: hidden;
}

.listbox {
  .tcard {
    overflow: hidden;
    cursor: pointer;
    padding-bottom: 23.96%;
    height: 0;
  }
  .cardone {
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }

    .titleh2 {
      position: absolute;
      box-sizing: border-box;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      background: #0003;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 1s;
      opacity: 0;
      span {
        display: block;
        text-align: center;
      }
    }
    .showtext .titleh2 {
      opacity: 1;
    }
  }
  .cardtwo {
    text-align: center;
    h2 {
      margin-top: 28%;
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
}
/deep/.searchteam {
  width: 460px;
  margin: -30px auto 30px;
  display: block;
  .el-input__inner {
    border-radius: 50px;
    height: 60px;
    padding: 0 50px 0 30px;
  }
  .el-input__suffix {
    right: 25px;
    color: #3385ff;
    line-height: 60px;
    // font-size: 34px;
    cursor: pointer;
    .iconfont {
      font-size: 20px;
    }
  }
}
.teamlist {
  width: 1200px;
  margin: 0 auto;
  & > li {
    padding: 15px;
    width: 25%;
    float: left;
    box-sizing: border-box;

    .onebox {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 10px #e0e0e0;
      padding: 5px;
      height: 322px;
    }
  }
  .left {
    width: 100%;
    height: 176px;
    overflow: hidden;
    display: block;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    flex: 1;
    cursor: pointer;
    color: #666;
    .titleh2 {
      font-size: 16px;
      margin: 50px auto 15px;
      text-align: center;
      .addteam {
        float: right;
      }
    }
    .btnbox {
      text-align: center;
    }
    .textp {
      font-size: 13px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .teammember {
      overflow: hidden;
      margin-top: 9px;
      position: absolute;
      top: 100px;
      left: 50%;
      margin-left: -47px;
      span {
        width: 94px;
        height: 94px;
        border: 1px solid #e9e9e9;
        border-radius: 100px;
        overflow: hidden;
        float: left;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          float: left;
        }
      }
    }
  }
}
.bannerbox {
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
.nomore {
  height: 150px;
  line-height: 150px;
  text-align: center;
  font-size: 14px;
  color: #999;
}
</style>